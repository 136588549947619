import React from "react";
import { selectIsLoading } from "../../../redux/LoadingReducer";
import { useSelector } from "react-redux";
function Loader({ loaderStyle, containerClassName }) {
  const isLoading = useSelector(selectIsLoading);
  return (
    <>
      {isLoading && <div
        className={`flex w-full justify-center items-center h-[100dvh] fixed inset-0 ${containerClassName} bg-[#ffffff80] z-[9999999]`}
      >
        <img src="/gif/loader.gif" alt="" />
      </div>}
    </>
  );
}

export default Loader;
