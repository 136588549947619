import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFirebaseData from "../../hooks/useFirebaseData";
import DzText from "../../components/common/dzText/DzText";
const AboutBanner = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentContectHeader");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex items-center justify-between gap-[24px] bg-[#F46D4C] px-[4vw] py-[82px]">
      <div className="flex flex-col gap-[12px]">
        <DzText className="text-[#FFFFFF] font-[500]">
          {showData?.message}
        </DzText>
        <DzText className="text-[48px] font-[600] text-[#FFF]">
          {showData?.heading}
        </DzText>
      </div>
      <DzText className="text-[#FFFFFF] max-lg:hidden">
        {showData?.detail}
      </DzText>
    </div>
  );
};
export default AboutBanner;