import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAo64AQBuMzc4R5lzs7DIjtHs8CPguSVuQ",
  authDomain: "fiindustries.firebaseapp.com",
  projectId: "fiindustries",
  storageBucket: "fiindustries.appspot.com",
  messagingSenderId: "630615477654",
  appId: "1:630615477654:web:248da872665b35cbeb21b7",
  measurementId: "G-CFDET99HWR" 
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider()
export const storage = getStorage(app)
export const db = getFirestore(app)