import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFirebaseData from '../../hooks/useFirebaseData';
import DzText from '../../components/common/dzText/DzText';

const TaglineSection = () => {
    const { data, fetchData } = useFirebaseData();
    const [showData, setShowData] = useState();
    useEffect(() => {
      const fetchHeroData = async () => {
        await fetchData("contentAbout");
      };
      fetchHeroData();
    }, []);
    useEffect(() => {
      if (data && data.length > 0) {
        const fetchedContent = data[0];
        setShowData(fetchedContent);
      }
    }, [data]);
    return (
        <div className="flex items-center flex-col gap-[24px] px-[4vw] py-[64px]">
            <DzText className="text-center text-[36px] font-[600] text-[#101828] max-w-[780px]">
                {showData?.heading}
            </DzText>
            <DzText className="text-center text-[20px] text-[#475467] max-w-[780px]">
                {showData?.detail}
            </DzText>
        </div>
    )
}

export default TaglineSection