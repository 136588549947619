import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const AboutSection = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("homeAbout");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex items-center justify-between gap-[32px] px-[4vw] py-[64px] max-lg:flex-col-reverse">
      <img
        src={showData?.image}
        className="w-full h-[640px] max-lg:h-auto max-h-[640px] object-cover"
        alt=""
      />
      <div className="flex flex-col gap-[24px] w-full">
        <DzText className="text-[#101828] font-[700] text-[48px] max-lg:text-[32px]">
          {showData?.heading}
        </DzText>
        <DzText className="text-[#475467] font-[500]">
          {showData?.detail}
        </DzText>
        <Link
          to={"/about"}
          className="flex items-center gap-[24px] w-fit px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px]"
        >
          {t("AboutSection.button_text")}
        </Link>
      </div>
    </div>
  );
};
export default AboutSection;