import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "LoadingReducer",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const { setIsLoading } = slice.actions;

export const selectIsLoading = (state) => state.LoadingReducer.isLoading;

export default slice.reducer;
