import React from 'react'

const Snackbar = ({snackbarMessage}) => {
    return (
        <div className="bg-[#FF5C35] rounded-md p-[24px] pt-[10px] pb-[10px] fixed shadow-lg bottom-8 left-[50%] -translate-x-[50%]">
            <span className='text-[#FFF]'>
                {snackbarMessage}
            </span>
        </div>
    )
}

export default Snackbar;