import React from 'react'
import { useTranslation } from 'react-i18next';

const InputField = ({ type, placeholder = "First Name", label = "First Name", className, name, onChange, value }) => {
    const { t } = useTranslation();
    return (
        <div className={` ${className} flex flex-col gap-[6px] w-full`}>

            <label htmlFor="" className='text-[14px] text-[#344054]'>
                {t(label)}
            </label>

            <input type={type} placeholder={placeholder} style={{ border: '1px solid #D0D5DD' }} name={name} onChange={onChange} value={value} className=' w-full placeholder:text-[#667085] text-[#667085] p-[10px] px-[14px] outline-none rounded-[8px]' />

        </div> 
    )
}

export default InputField; 