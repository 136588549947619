import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Services from "../pages/services/Services";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import GetAQoute from "../pages/getAQoute/GetAQoute";
import { ROUTES } from "./RouteConstants";
import ServiceDetail from "../pages/serviceDetail/ServiceDetail";

const ReactRoute = () => {

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.about} element={<About />} />
        <Route path={ROUTES.contact} element={<Contact />} />
        <Route path={ROUTES.getAQoute} element={<GetAQoute />} />
        <Route path={ROUTES.services} element={<Services />} />
        {/* This route is for the detailed service page */}
        <Route path={ROUTES.serviceDetail} element={<ServiceDetail />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default ReactRoute;
