import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const ChooseSection = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("homeWhyUs");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex flex-col gap-[64px] w-full px-[4vw] py-[64px] max-lg:gap-[32px]">
      <div className="flex flex-col gap-[20px]">
        <DzText className="text-[36px] font-[600] text-[#101828] max-lg:text-[32px]">
          {showData?.heading}
        </DzText>
        <DzText className="text-[#475467]">{showData?.detail}</DzText>
      </div>
      <div className="flex items-center gap-[64px] w-full max-lg:flex-col max-lg:gap-[32px]">
        <div className="flex flex-col gap-[48px] w-full max-lg:gap-[24px]">
          <div className="flex items-start gap-[12px] max-lg:flex-col">
            <div className="p-2 bg-[#FFEEEE] rounded-full">
              <div className="p-2 bg-[#FF5C35] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#ffffff"
                  fill="none"
                >
                  <path
                    d="M6.09881 19C4.7987 18.8721 3.82475 18.4816 3.17157 17.8284C2 16.6569 2 14.7712 2 11V10.5C2 6.72876 2 4.84315 3.17157 3.67157C4.34315 2.5 6.22876 2.5 10 2.5H14C17.7712 2.5 19.6569 2.5 20.8284 3.67157C22 4.84315 22 6.72876 22 10.5V11C22 14.7712 22 16.6569 20.8284 17.8284C19.6569 19 17.7712 19 14 19C13.4395 19.0125 12.9931 19.0551 12.5546 19.155C11.3562 19.4309 10.2465 20.0441 9.14987 20.5789C7.58729 21.3408 6.806 21.7218 6.31569 21.3651C5.37769 20.6665 6.29454 18.5019 6.5 17.5"
                    stroke="#FFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col gap-[12px]">
              <DzText className="text-[20px] text-[#101828]">
                {showData?.qualityFeatureHeading}
              </DzText>
              <DzText className="text-[#475467]">
                {showData?.qualityFeatureDetail}
              </DzText>
            </div>
          </div>
          <div className="flex items-start gap-[12px] max-lg:flex-col">
            <div className="p-2 bg-[#FFEEEE] rounded-full">
              <div className="p-2 bg-[#FF5C35] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#ffffff"
                  fill="none"
                >
                  <path
                    d="M9.5 2V4"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 2V4"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 20V22"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 20V22"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 14.5L20 14.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 9.5L2 9.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 14.5L2 14.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 9.5L20 9.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.6939 8.5L10.6029 11.0373C10.3788 11.3092 10.5394 11.7005 10.9048 11.7729L13.0952 12.2068C13.4848 12.284 13.6334 12.7171 13.361 12.9815L10.7666 15.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17157C20 6.34315 20 8.22876 20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34315 20 5.17157 18.8284C4 17.6569 4 15.7712 4 12Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col gap-[12px]">
              <DzText className="text-[20px] text-[#101828]">
                {showData?.customizationFeatureHeading}
              </DzText>
              <DzText className="text-[#475467]">
                {showData?.customizationFeatureDetail}
              </DzText>
            </div>
          </div>
          <div className="flex items-start gap-[12px] max-lg:flex-col">
            <div className="p-2 bg-[#FFEEEE] rounded-full">
              <div className="p-2 bg-[#FF5C35] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#ffffff"
                  fill="none"
                >
                  <path
                    d="M20 10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2H10C6.22876 2 4.34315 2 3.17157 3.17157C2 4.34315 2 6.22876 2 10V12C2 15.7712 2 17.6569 3.17157 18.8284C4.23467 19.8915 5.8857 19.99 9 19.9991H9.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5285 21.0596C12.8812 21.1735 11.249 13.4909 12.3697 12.37C13.4905 11.2491 21.1736 12.8801 21.0598 14.5274C20.9814 15.6063 19.1553 16.033 19.2086 16.9918C19.2243 17.2726 19.579 17.5286 20.2885 18.0404C20.7815 18.3961 21.2841 18.7415 21.7687 19.1086C21.9621 19.2551 22.0385 19.5015 21.9817 19.7337C21.7089 20.8491 20.854 21.7078 19.7341 21.9817C19.5018 22.0385 19.2555 21.9621 19.109 21.7686C18.742 21.284 18.3967 20.7813 18.041 20.2882C17.5292 19.5786 17.2733 19.2239 16.9925 19.2082C16.0339 19.1549 15.6072 20.9812 14.5285 21.0596Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M2 7H20"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col gap-[12px]">
              <DzText className="text-[20px] text-[#101828]">
                {showData?.fiberglassFeatureHeading}
              </DzText>
              <DzText className="text-[#475467]">
                {showData?.fiberglassFeatureDetail}
              </DzText>
            </div>
          </div>
        </div>
        <div className="w-full">
          <img
            src={showData?.image}
            className="w-full object-cover h-[800px] max-lg:h-[600px]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default ChooseSection;
