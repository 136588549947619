import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { productData } from "../../productData";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Tooltip } from "@mui/material";
import Image from "../../components/image/Image";
import useFirebaseData from "../../hooks/useFirebaseData";
import DzText from "../../components/common/dzText/DzText";

const ServiceDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [tooltipText, setTooltipText] = useState("Share");
  const { fetchDocumentById, singleData } = useFirebaseData();

  useEffect(() => {
    fetchDocumentById("contentServices", id);
  }, []);

  // const [serviceDetail, setServiceDetail] = useState(null);
  // useEffect(() => {
  //     setServiceDetail(serviceDetail?.find(item => item?.id === location?.state?.id));
  // }, [serviceDetail, location.state?.id]);
  // useEffect(() => {
  //     setServiceDetail(serviceDetail?.find(item => item?.id === location?.state?.id));
  // }, [serviceDetail, location.state?.id]);
  const handleShare = () => {
    navigator.clipboard.writeText(
      `https://fiindustries.org${location.pathname}`
    );
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("Share");
    }, 3000);
  };
  console.log(singleData, "singleData");
  return (
    <div className="flex items-center gap-[24px] px-[4vw] py-[64px] max-[880px]:flex-col">
      <div className="sliderServiceDetail flex flex-col gap-[36px] w-full max-w-[44%] max-[1100px]:max-w-[50%] max-[880px]:max-w-[100%] max-[880px]:gap-[10px]">
        {/* <Swiper pagination={{ clickable: true }} className="w-full overflow-hidden h-[500px] rounded-[10px]">
                {product?.images?.map((item, index) => {
                    return (
                        <SwiperSlide key={index} className="h-full">
                            <img src={item?.imgUrl} alt={item?.imgUrl} className='min-w-full min-h-full object-cover' />
                        </SwiperSlide>
                    )
                })}
            </Swiper> */}
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full h-[600px] max-[880px]:h-[400px] sliderServiceDetail"
        >
          {singleData?.images?.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="h-full overflow-hidden rounded-[10px]"
              >
                <Image
                  src={item?.url}
                  alt={item?.path}
                  className="min-w-full min-h-full object-cover"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 4,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full"
        >
          {singleData?.images?.map((item, index) => {
            return (
              <SwiperSlide key={index} className="h-full slide-transparent">
                <Image
                  src={item?.url}
                  alt={item?.path}
                  className="w-full rounded-[10px] h-[100px] object-cover max-[880px]:h-[80px]"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="flex flex-col gap-[64px] w-full">
        <div className="flex items-center justify-between gap-[32px]">
          <div className="flex flex-col gap-[12px]">
            <DzText className="text-[28px] font-[600]">
              {singleData?.heading}
            </DzText>
            <span className="text-[#B9BBBF]">Fi Industries</span>
          </div>
          <Tooltip title={tooltipText} placement="left" arrow>
            <div
              className={` ${tooltipText} flex items-center justify-center rounded-[10px] bg-[#EDF0F8] p-[8px] opacity-60 transition cursor-pointer hover:opacity-100`}
              onClick={handleShare}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.01439 9.08916C5.81261 8.72608 5.49598 8.44029 5.1142 8.27664C4.73242 8.11299 4.30711 8.08075 3.90502 8.18497C3.50293 8.2892 3.14684 8.52399 2.89263 8.85249C2.63842 9.181 2.50049 9.58462 2.50049 9.99999C2.50049 10.4154 2.63842 10.819 2.89263 11.1475C3.14684 11.476 3.50293 11.7108 3.90502 11.815C4.30711 11.9192 4.73242 11.887 5.1142 11.7233C5.49598 11.5597 5.81261 11.2739 6.01439 10.9108M6.01439 9.08916C6.16439 9.35916 6.25023 9.66916 6.25023 9.99999C6.25023 10.3308 6.16439 10.6417 6.01439 10.9108M6.01439 9.08916L13.9861 4.66082M6.01439 10.9108L13.9861 15.3392M13.9861 4.66082C14.1029 4.88094 14.2625 5.07555 14.4555 5.23327C14.6485 5.39098 14.8709 5.50865 15.1099 5.57939C15.3489 5.65012 15.5996 5.67251 15.8473 5.64524C16.095 5.61796 16.3348 5.54158 16.5527 5.42054C16.7705 5.29951 16.9621 5.13626 17.1161 4.94034C17.2701 4.74441 17.3836 4.51975 17.4497 4.27948C17.5159 4.03921 17.5336 3.78815 17.5016 3.54099C17.4696 3.29382 17.3887 3.05552 17.2636 2.83999C17.0169 2.41528 16.6135 2.10427 16.1401 1.97384C15.6666 1.8434 15.1608 1.90394 14.7315 2.14245C14.3022 2.38096 13.9836 2.7784 13.8442 3.24932C13.7048 3.72024 13.7557 4.22706 13.9861 4.66082ZM13.9861 15.3392C13.8664 15.5545 13.7904 15.7912 13.7623 16.0359C13.7342 16.2806 13.7546 16.5285 13.8222 16.7653C13.8899 17.0021 14.0036 17.2233 14.1567 17.4162C14.3099 17.6091 14.4995 17.77 14.7148 17.8896C14.9301 18.0092 15.1669 18.0852 15.4116 18.1133C15.6563 18.1414 15.9041 18.1211 16.1409 18.0534C16.3778 17.9857 16.599 17.8721 16.7919 17.7189C16.9848 17.5658 17.1456 17.3761 17.2652 17.1608C17.5068 16.726 17.5657 16.213 17.4291 15.7347C17.2924 15.2564 16.9713 14.852 16.5365 14.6104C16.1016 14.3688 15.5886 14.3099 15.1103 14.4466C14.632 14.5832 14.2276 14.9043 13.9861 15.3392Z"
                  stroke="#3A4980"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-col gap-[20px]">
          <h2 className="text-[#344054] text-[24px] font-[700]">
            {t("ProductsDetail.productDescription")}
            {/* {t(product.detail.detail)} */}
          </h2>
          <DzText className="text-[#667085]">{singleData?.detail}</DzText>
        </div>
      </div>
    </div>
  );
};
export default ServiceDetail;
