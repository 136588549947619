import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../image/Image";
import DzText from "../common/dzText/DzText";
const Section = ({
  heading = "Our Purpose",
  className,
  imageUrl,
  description = "Our fiberglass company's fundamental purpose is to excel in the manufacturing and supply of top-tier fiberglass products. We aim to deliver high-quality solutions that cater to the diverse needs of industries and clients, ensuring our products are not only reliable and durable but also environmentally responsible. Our commitment to sustainability drives us to employ eco-friendly materials and processes, minimizing our environmental impact.",
}) => {
  return (
    <div
      className={` ${className} flex items-center justify-between px-[4vw] py-[64px] max-lg:flex-col gap-[90px] max-lg:gap-[32px]`}
    >
      <div className="flex flex-col gap-[16px] max-lg:items-center w-full">
        <DzText className="text-[#101828] text-[30px] font-[600] max-lg:text-center">
          {heading}
        </DzText>
        <DzText className="text-[18px] text-[#1D1D1B] max-w-[100%] max-lg:text-[16px] max-lg:text-center">
          {description}
        </DzText>
      </div>
      <Image className="max-w-[100%]" width="100%" src={imageUrl} alt="" />
    </div>
  );
};
export default Section;