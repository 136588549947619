import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../reactRoute/RouteConstants";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const HeroSection = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("homeHero");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex items-center max-lg:flex-col justify-between px-[4vw] py-[64px] w-full overflow-hidden max-lg:gap-[24px]">
      <div className="flex flex-col gap-[20px] max-lg:items-center">
        <h1 className="font-[700] text-[48px] max-w-[400px] max-lg:text-center max-sm:text-[32px]">
          <DzText className="text-[#333]">{showData?.heading}</DzText>{" "}
        </h1>
        <DzText className="font-[500] text-[18px] max-sm:text-[16px] max-w-[460px] max-lg:text-center">
          {showData?.detail}
        </DzText>
        <Link
          to={ROUTES.getAQoute}
          className="flex items-center gap-[24px] w-fit px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px]"
        >
          {t("HeroSection.get_quote")}
        </Link>
      </div>
      <img
        src={showData?.image}
        className="w-full max-sm:max-w-[450px] translate-x-[60px] max-sm:translate-x-[40px] max-xl:max-w-[600px] max-w-[700px] max-lg:max-w-[550px]"
        alt=""
      />
    </div>
  );
};
export default HeroSection;
