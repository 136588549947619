import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/useOutsideClick";
import { getLocalStorage } from "../../local_storage";
import i18next from "i18next";
import useHandleLanguage from "../../hooks/useHandleLanguage";
const Navbar = () => {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const languageModalRef = useRef(null);
  const [langChangeModal, setLangChangeModal] = useState(false);
  const { setLanguage } = useHandleLanguage();
  useOutsideClick(languageModalRef, () => setLangChangeModal(false));
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    getLocalStorage("i18nextLng")
  );
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
  useEffect(() => {
    setActiveLink(location.pathname);
    setMobileNavbar(false);
  }, [location]);
  function handleClick(lang) {
    i18next.changeLanguage(lang);
    setLanguage(lang);
    setActiveLanguage(lang);
  }
  return (
    <div className="flex items-center justify-between gap-[24px] px-[4vw] py-2">
      <Link to={"/"}>
        <img
          src="images/logo.png"
          className="cursor-pointer max-w-[40px]"
          alt=""
        />
      </Link>
      <div className="flex items-center gap-[24px] max-lg:hidden">
        <Link
          to={"/"}
          className={
            activeLink === "/"
              ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
              : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
          }
        >
          {t("Navbar.home")}
        </Link>
        <Link
          to={"/about"}
          className={
            activeLink === "/about"
              ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
              : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
          }
        >
          {t("Navbar.about")}
        </Link>
        <Link
          to={"/services"}
          className={
            activeLink === "/services"
              ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
              : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
          }
        >
          {t("Navbar.services")}
        </Link>
        <Link
          to={"/contact"}
          className={
            activeLink === "/contact"
              ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
              : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
          }
        >
          {t("Navbar.contact")}
        </Link>
      </div>
      <div className="gap-[24px] flex items-center">
        <div ref={languageModalRef} className="relative">
          <i
            className="ri-global-line text-[24px] text-[#333] cursor-pointer transition hover:text-[#FF5C35]"
            onClick={() => setLangChangeModal(!langChangeModal)}
          ></i>
          {langChangeModal && (
            <div className="flex flex-col gap-[16px] p-[16px] shadow-lg rounded-[10px] absolute w-[160px] top-[100%] right-0 bg-white z-[9]">
              <span
                onClick={() => handleClick("en-US")}
                className={` hover:text-[#FF5C35] transition cursor-pointer ${activeLanguage === "en-US" ? "text-[#FF5C35]" : "text-[#666]"
                  }`}
              >
                English
              </span>
              <span
                onClick={() => handleClick("ar")}
                className={` hover:text-[#FF5C35] transition cursor-pointer ${activeLanguage === "ar" ? "text-[#FF5C35]" : "text-[#666]"
                  }`}
              >
                Arabic
              </span>
            </div>
          )}
        </div>
        <i
          onClick={() => setMobileNavbar(!mobileNavbar)}
          className="ri-menu-4-line cursor-pointer text-[#333] text-[24px] lg:hidden"
        ></i>
        <Link
          to={"/getAQoute"}
          className="fw-[600] cursor-pointer rounded-[8px] bg-[#FF5C35] px-[18px] py-[10px] text-white hover:shadow-lg transition"
        >
          {t("Navbar.get_quote")}
        </Link>
      </div>
      {mobileNavbar && (
        <div className="fixed w-full h-full inset-0 z-[9] bg-[#FFF] flex items-center justify-center">
          <i
            onClick={() => setMobileNavbar(false)}
            className="ri-close-line absolute top-[20px] right-[20px] text-[#333] transition hover:text-[#FF5C35] text-[24px]"
          ></i>
          <div className="flex flex-col items-center justify-center gap-[24px]">
            <Link
              to={"/"}
              className={
                activeLink === "/"
                  ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
                  : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
              }
            >
              {t("Navbar.home")}
            </Link>
            <Link
              to={"/about"}
              className={
                activeLink === "/about"
                  ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
                  : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
              }
            >
              {t("Navbar.about")}
            </Link>
            <Link
              to={"/services"}
              className={
                activeLink === "/services"
                  ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
                  : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
              }
            >
              {t("Navbar.services")}
            </Link>
            <Link
              to={"/contact"}
              className={
                activeLink === "/contact"
                  ? "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#FF5C35]"
                  : "font-[600] cursor-pointer text-[16px] hover:opacity-70 transition text-[#475467]"
              }
            >
              {t("Navbar.contact")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;