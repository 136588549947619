import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedLanguage, setSelectedLanguage } from '../redux/HandleMultilingualReducer';
function useHandleLanguage() {
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(selectSelectedLanguage);
    const setLanguage = (language) => {
        dispatch(setSelectedLanguage(language));
    };
    return { selectedLanguage, setLanguage };
}
export default useHandleLanguage;