import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const ContactBanner = () => {
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentContectHeader");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex flex-col gap-[24px] px-[4vw] py-[64px]">
      <div className="flex flex-col">
        <DzText className="text-[#FF5C35] font-[600]">
          {showData?.message}
        </DzText>
        <DzText className="text-[#101828] text-[48px] max-lg:text-[40px] max-md:text-[36px] max-sm:text-[32px] font-[600]">
          {showData?.heading}
        </DzText>
      </div>
      <DzText className="text-[#475467] ">{showData?.detail}</DzText>
    </div>
  );
};
export default ContactBanner;