import ReactRoute from "./reactRoute/ReactRoute";
import "./globals.css";
import Loader from "./components/common/loader/Loader";
function App() {
  return (
    <>
      <ReactRoute />
      <Loader />
    </>
  );
}

export default App;
