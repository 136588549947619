import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import HeroSection from './components/HeroSection';
import ChooseSection from './components/ChooseSection';
import AboutSection from './components/AboutSection';

const Home = () => {
  // const { selectedLanguage } = useHandleLanguage();
  // const { data, fetchData, updateData } = useFirebaseData();
  // const { imageUrl, uploadProgress, uploadImage } = useFirebaseImage();
  // const { changeModeOn, onChangeMode, offChangeMode } = useChangeMode();

  // useEffect(() => {
  //   const fetchHeroData = async () => {
  //     await fetchData("homeHero");
  //   };
  //   fetchHeroData();
  // }, [])
  return (
    <>
      <HeroSection />
      <ChooseSection />
      <AboutSection />
    </>
  )
}

export default Home;