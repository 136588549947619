import { useDispatch, useSelector } from "react-redux";
import {
  hideSnackBarMessage,
  setSnackBarError,
  setSnackBarMessage,
  snackBarErrorSelector,
  snackBarMessageSelector,
} from "../redux/SnackBarReducer";
export function useSnackBarManager() {
  const dispatch = useDispatch();
  const isShowSnackBar = useSelector(snackBarMessageSelector);
  const isErrorTrue = useSelector(snackBarErrorSelector);
  const fnShowSnackBar = (message, error) => {
    if (message) {
      dispatch(setSnackBarMessage(message));
    }
    dispatch(setSnackBarError(error));
    setTimeout(() => {
      dispatch(hideSnackBarMessage());
    }, 3000);
  };
  const fnHideSnackBar = () => {
    dispatch(hideSnackBarMessage());
  };
  return {
    isShowSnackBar,
    isErrorTrue,
    fnShowSnackBar,
    fnHideSnackBar,
  };
}