import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputField from "../../components/inputField/InputField";
import Snackbar from "../../components/snackbar/Snackbar";
import ContactBanner from "./components/ContactBanner";
import ContactCards from "./components/ContactCards";
import MapSection from "./components/MapSection";
import ContactFrom from "./components/ContactFrom";
const Contact = () => {
  return (
    <>
      <ContactBanner />
      <ContactCards />
      <MapSection />
      <ContactFrom />
    </>
  );
};
export default Contact;