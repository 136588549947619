import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "./redux/store";
import App from './App';
import './I18n/I18n.js';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <App />
  </Provider>
);