import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const MapSection = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentContectMap");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="flex flex-col px-[4vw] py-[64px] gap-[64px]">
      <div className="flex items-end justify-between gap-[24px] max-sm:flex-col w-full">
        <div className="flex flex-col gap-[24px]  w-full">
          <div className="flex flex-col gap-[24px]">
            <DzText className="text-[#FF5C35] font-[600]">
              {showData?.subTitle}
            </DzText>
            <DzText className="text-[#101828] text-[32px] max-sm:text-[24px] font-[600]">
              {showData?.heading}
            </DzText>
          </div>
          <DzText className="text-[#475467] ">
            {showData?.detail}
          </DzText>
        </div>
        <div className="flex items-center gap-[10px] max-lg:w-full">
          <img className="w-[48px]" src="images/locationIcon.png" alt="" />

          <div className="flex flex-col gap-[4px] w-[200px]">
            <DzText className="text-[#101828] font-[600]">
              {showData?.location}
            </DzText>
          </div>
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463877.31242688524!2d46.49288432992219!3d24.725455373149728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1711974281732!5m2!1sen!2s"
        className="w-full rounded-[10px] outline-none overflow-hidden"
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        title="map"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />

      {/*  */}
    </div>
  );
};

export default MapSection;
