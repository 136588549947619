// Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='flex items-center justify-between px-[4vw] py-[32px] flex-wrap gap-[24px]'>

      <div className="flex flex-col gap-[24px]">

        <div className="flex items-center gap-[24px]">

          <img src="images/logo.png" className='max-w-[80px]' alt="" />

          <span className='font-[600] text-[24px] max-w-[200px] text-[#333]'>
            {t('Footer.company_name')}
          </span>

        </div>

        <p className="text-[#475467] font-[500] max-w-[560px]">
          {t('Footer.company_description')}
        </p>

        <div className="flex items-center gap-[12px]">
          <a target='_blank' href='#' className="w-[46px] h-[46px] flex items-center justify-center bg-[#fef4ea] text-[#FF5C35] text-[20px] rounded-full ri-facebook-fill"></a>
          <a target='_blank' href='#' className="w-[46px] h-[46px] flex items-center justify-center bg-[#fef4ea] text-[#FF5C35] text-[20px] rounded-full ri-twitter-x-fill"></a>
          <a target='_blank' href='#' className="w-[46px] h-[46px] flex items-center justify-center bg-[#fef4ea] text-[#FF5C35] text-[20px] rounded-full ri-linkedin-fill"></a>
          <a target='_blank' href='https://www.instagram.com/fiindustries?igsh=d3UxZ2dtNXVhZ3Vr' className="w-[46px] h-[46px] flex items-center justify-center bg-[#fef4ea] text-[#FF5C35] text-[20px] rounded-full ri-instagram-line"></a>
        </div>

        <p className="text-[#475467] font-[500] max-w-[560px]">
          {t('Footer.quote_description')}
        </p>

      </div>

      <div className="flex flex-col gap-[24px]">

        <h2 className='font-[600] text-[24px] max-w-[200px] text-[#333]'>
          {t('Footer.important_links')}
        </h2>

        <div className="flex flex-col gap-[12px]">

          <Link to={'/'} className='text-[#475467] font-[500] max-w-[560px]'>
            {t('Footer.home')}
          </Link>

          <Link to={'/about'} className='text-[#475467] font-[500] max-w-[560px]'>
            {t('Footer.about')}
          </Link>

          <Link to={'/services'} className='text-[#475467] font-[500] max-w-[560px]'>
            {t('Footer.services')}
          </Link>

          <Link to={'/contact'} className='text-[#475467] font-[500] max-w-[560px]'>
            {t('Footer.contact')}
          </Link>

        </div>

      </div>

      <div className="flex flex-col gap-[24px]">

        <h2 className='font-[600] text-[24px] max-w-[200px] text-[#333]'>
          {t('Footer.get_a_quote')}
        </h2>

        <p className='text-[#475467] font-[500] max-w-[320px]'>
          {t('Footer.quote_description')}
        </p>

        <Link to={'/getAQoute'} className='flex items-center gap-[24px] w-fit px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px]'>
          {t('Footer.get_a_quote')}
        </Link>

      </div>

    </div>
  );
};

export default Footer;
