import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import StillSection from "../../components/stillSection/StillSection";
import GallerySection from "./GallerySection";
import TaglineSection from "./TaglineSection";
import AboutBanner from "./AboutBanner";
import useFirebaseData from "../../hooks/useFirebaseData";
const About = () => {
  const { data: dataPurpose, fetchData: fetchDataPurpose } = useFirebaseData();
  const { data: dataStory, fetchData: fetchDataStory } = useFirebaseData();
  const [showDataOfPurpose, setShowDataOfPurpose] = useState();
  const [showDataOfStory, setShowDataOfStory] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchDataPurpose("contentAboutPurpose");
    };
    fetchHeroData();
    const fetchStoryData = async () => {
      await fetchDataStory("contentAboutStory");
    }
    fetchStoryData();
  }, []);
  useEffect(() => {
    if (dataPurpose && dataPurpose.length > 0) {
      const fetchedContent = dataPurpose[0];
      setShowDataOfPurpose(fetchedContent);
    }
  }, [dataPurpose]);
  useEffect(() => {
    if (dataStory && dataStory.length > 0) {
      const fetchedContent = dataStory[0];
      setShowDataOfStory(fetchedContent);
    }
  }, [dataStory]);
  console.log(showDataOfStory?.image, "showDataOfStory?.image")
  console.log(showDataOfPurpose?.image, "showDataOfPurpose?.image")
  return (
    <>
      <AboutBanner />
      <TaglineSection />
      <Section
        heading={showDataOfPurpose?.heading}
        description={showDataOfPurpose?.detail}
        imageUrl={showDataOfPurpose?.image}
      />
      <Section
        className={"flex-row-reverse"}
        heading={showDataOfStory?.heading}
        description={showDataOfStory?.detail}
        imageUrl={showDataOfStory?.image}
      />
      <GallerySection />
      <StillSection />
    </>
  );
};
export default About;
