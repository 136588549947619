import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputField from "../../../components/inputField/InputField";
import Snackbar from "../../../components/snackbar/Snackbar";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";
const ContactFrom = () => {
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState();
  const [showData, setShowData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { addData, data: contactData, fetchData } = useFirebaseData();
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentContectForm");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (contactData && contactData.length > 0) {
      const fetchedContent = contactData[0];
      setShowData(fetchedContent);
    }
  }, [contactData]);
  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async () => {
    if (!data.name || !data.email || !data.phone) {
      setSnackbar(true);
      setMessage("Please fill all the required fields.");
      setError(true);
      setTimeout(() => {
        setSnackbar(false);
      }, 3000);
      return;
    }
    setIsLoading(true);
    try {
      const formattedData = Object.values(data);
      const dataArray = [formattedData];
      const response = await fetch(
        "https://v1.nocodeapi.com/fiindustries/google_sheets/HlEUTxqlyxiSNjUI?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataArray),
        }
      );
      addData("contactForm", { ...data, date: new Date().toLocaleString() });
      console.log(response.ok);
      if (response.ok) {
        setSnackbar(true);
        setMessage("Form Submitted Successfully");
        setData({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
      } else {
        console.log(response);
        setSnackbar(true);
        setMessage("Something went wrong. Please try again later.");
        setError(true);
      }
      setTimeout(() => {
        setSnackbar(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {snackbar && <Snackbar snackbarMessage={message} />}
      <div className="flex flex-col items-center gap-[64px] px-[4vw] py-[64px]">
        <div className="flex flex-col gap-[0px] items-center">
          <DzText className="text-[#FF5C35] font-[600]">
            {showData?.subTitle}
          </DzText>
          <DzText className="text-[#101828] text-[32px] max-sm:text-[24px] font-[600]">
            {showData?.heading}
          </DzText>
          <DzText className="text-[#475467] mt-[12px]">
            {showData?.detail}
          </DzText>
        </div>
        <div className="grid grid-cols-2 min-w-[460px] max-sm:min-w-[320px] gap-[32px] max-sm:gap-[16px] max-sm:flex max-sm:flex-col max-sm:w-full">
          <InputField
            placeholder={t("ContactForm.first_name")}
            label={t("ContactForm.first_name")}
            type="text"
            className="col-span-2"
            name="name"
            value={data.name}
            onChange={handleOnChange}
          />
          <InputField
            placeholder={t("ContactForm.email")}
            label={t("ContactForm.email")}
            type="email"
            className="col-span-2"
            name="email"
            value={data.email}
            onChange={handleOnChange}
          />
          <InputField
            placeholder={t("ContactForm.phone")}
            label={t("ContactForm.phone")}
            type="number"
            className="col-span-2"
            name="phone"
            value={data.phone}
            onChange={handleOnChange}
          />
          <div className="flex flex-col gap-[6px] w-full col-span-2">
            <label htmlFor="" className="text-[14px] text-[#344054]">
              {t("ContactForm.message")}
            </label>
            <textarea
              style={{ border: "1px solid #D0D5DD" }}
              cols="30"
              rows="10"
              placeholder={t("ContactForm.message")}
              className="w-full placeholder:text-[#667085] text-[#667085] p-[10px] px-[14px] outline-none rounded-[8px] resize-none"
              value={data.message}
              onChange={handleOnChange}
              name="message"
            ></textarea>
          </div>
          <button
            onClick={handleSubmit}
            className="flex items-center gap-[24px] px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px] w-full col-span-2 text-center justify-center"
          >
            {!isLoading && t("ContactSection.form_send_message")}
            {isLoading && <span>IsLoading...</span>}
          </button>
        </div>
      </div>
    </>
  );
};
export default ContactFrom;
