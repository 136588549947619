import { useState } from "react";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import useHandleLoading from "./useHandleLoading";
import { useSnackBarManager } from "./useSnackBarManager";
function useFirebaseData() {
  const { fnHandleLoading } = useHandleLoading();
  const { fnShowSnackBar } = useSnackBarManager();
  const [data, setData] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const fetchData = async (collectionId) => {
    fnHandleLoading(true);
    const collectionRef = collection(db, collectionId);
    try {
      const dataSnapshot = await getDocs(collectionRef);
      const fetchedData = dataSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setData(fetchedData);
      fnShowSnackBar("Data fetched successfully");
    } catch (error) {
      console.error("Error fetching data: ", error);
      fnShowSnackBar("Error fetching data", "error");
    } finally {
      fnHandleLoading(false);
    }
  };
  const fetchDocumentById = async (collectionId, documentId) => {
    fnHandleLoading(true);
    try {
      const docRef = doc(db, collectionId, documentId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const documentData = { ...docSnap.data(), id: docSnap.id };
        setSingleData(documentData);
        fnShowSnackBar("Document fetched successfully");
        return documentData;
      } else {
        fnShowSnackBar("No such document!", "error");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
      fnShowSnackBar("Error fetching document", "error");
      return null;
    } finally {
      fnHandleLoading(false);
    }
  };

  const updateData = async (collectionId, documentId, updatedData) => {
    fnHandleLoading(true);
    try {
      const docRef = doc(db, collectionId, documentId);
      await updateDoc(docRef, updatedData);
      fnShowSnackBar("Data successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
      fnShowSnackBar("Error updating document", "error");
    } finally {
      fnHandleLoading(false);
    }
  };
  const addData = async (collectionId, newData) => {
    fnHandleLoading(true);
    try {
      const collectionRef = collection(db, collectionId);
      await addDoc(collectionRef, newData);
      fnShowSnackBar("Data successfully added!");
    } catch (error) {
      console.error("Error adding document: ", error);
      fnShowSnackBar("Error adding document", "error");
    } finally {
      fnHandleLoading(false);
    }
  };
  return {
    data,
    fetchData,
    updateData,
    addData,
    singleData,
    fetchDocumentById,
  };
}
export default useFirebaseData;
