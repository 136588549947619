import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Image from "../../components/image/Image";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useFirebaseData from "../../hooks/useFirebaseData";
import DzText from "../../components/common/dzText/DzText";
const GallerySection = () => {
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [gallerySlider, setGallerySlider] = useState(false);
  const { data, fetchData } = useFirebaseData();
  const [showData, setShowData] = useState();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("gallery");
    };
    fetchHeroData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedContent = data[0];
      setShowData(fetchedContent);
    }
  }, [data]);
  return (
    <div className="px-[4vw] py-[64px] flex gap-[24px] flex-col">
      <div className="flex flex-col gap-[20px]">
        <DzText className="text-[#101828] text-[36px] font-[600]">
          {showData?.heading}
        </DzText>
        <DzText className="text-[#475467] text-[20px] font-[500]">
          {showData?.detail}
        </DzText>
      </div>
      <div className="grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-[24px]">
        {showData?.images?.map((image, index) => (
          <img
            src={image?.url}
            alt=""
            className="w-full h-[500px] cursor-pointer object-cover"
            onClick={() => setGallerySlider(true)}
          />
        ))}
      </div>
      <div
        className={` ${
          gallerySlider
            ? "sliderServiceDetail gallerySlider__open"
            : "gallerySlider__closed"
        } gallery__slider flex flex-col items-center gap-[20px] fixed inset-0 p-[32px] bg-[rgba(0,0,0,0.8)]`}
      >
        <i
          onClick={() => setGallerySlider(false)}
          className="ri-close-line text-[24px] text-[#FFF] cursor-pointer absolute right-[10px] top-[10px] transition hover:text-[#EEE] max-sm:top-[5px] max-sm:right-[5px]"
        ></i>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="max-w-[400px] max-sm:max-w-[100%] h-[100%] sliderServiceDetail rounded-[10px]"
        >
          {
            showData?.images?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image?.url}
                  alt=""
                  className="w-full h-full object-cover rounded-[10px]"
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 10,
            },
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full max-h-[220px]"
        >
          {
            showData?.images?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image?.url}
                  alt=""
                  className="w-full h-full object-cover rounded-[10px]"
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  );
};
export default GallerySection;
