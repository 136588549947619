// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const Languages = ["en-US", "ar"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["path", "navigator"],
    },
    react: {
      bindI18n: 'languageChanged',
    },
  });

const setLanguageDirection = (language) => {
  document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';

  // Assuming 'slider' is the class name of your slider element
  const slider = document.querySelector('.sliderServiceDetail');
  if (slider) {
    slider.style.direction = 'ltr';
  }
};


i18n.on('languageChanged', setLanguageDirection);
setLanguageDirection(i18n.language);

export default i18n;
