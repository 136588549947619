import React, { useEffect, useState } from "react";
import "./GetAQoute.css";
import { useTranslation } from "react-i18next";
import { productData } from "../../productData";
import InputField from "../../components/inputField/InputField";
import Snackbar from "../../components/snackbar/Snackbar";
import { useLocation } from "react-router-dom";
import useFirebaseData from "../../hooks/useFirebaseData";
const GetAQoute = () => {
  const location = useLocation();
  const serviceId = location?.state?.id;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState();
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const { addData } = useFirebaseData();
  const { data: contentServices, fetchData } = useFirebaseData();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
    email: "",
    jobTitle: "",
    product: "",
    message: "",
  });
  console.log(contentServices, "contentServices")
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentServices");
    };
    fetchHeroData()
  }, []);
  useEffect(()=> {
    setFilteredProducts(contentServices)
  }, [contentServices])
  
  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const filterProductServiceName = filteredProducts?.find(
      (item) => item?.id == serviceId
    )?.heading?.en;
    setData({ ...data, product: filterProductServiceName || "" });
  }, [filteredProducts]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (
      !data.firstName ||
      !data.lastName ||
      !data.email ||
      !data.phone ||
      !data.product
    ) {
      setSnackbar(true);
      setMessage("Please fill all the required fields.");
      setError(true);
      setTimeout(() => {
        setSnackbar(false);
      }, 3000);
      return;
    }
    try {
      const formattedData = Object.values(data);
      const dataArray = [formattedData];
      const response = await fetch(
        "https://v1.nocodeapi.com/fiindustries/google_sheets/XRDjTYprhITSOgEj?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataArray),
        }
      );
      await addData("quoteForm", {
        ...data,
        name: data.firstName + " " + data.lastName,
        date: new Date().toLocaleString(),
      });
      if (response.ok) {
        setSnackbar(true);
        setMessage("Form Submitted Successfully");
        setData({
          firstName: "",
          lastName: "",
          address: "",
          phone: "",
          email: "",
          message: "",
        });
      } else {
        console.log(response);
        setSnackbar(true);
        setMessage("Something went wrong. Please try again later.");
        setError(true);
      }
      setTimeout(() => {
        setSnackbar(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="qoute__page">
      <div className="qoute__image"></div>
      <div className="qoute__content">
        <InputField
          label={t("form.firstName")}
          placeholder={t("form.firstNamePlaceholder")}
          type={"text"}
          name="firstName"
          value={data.firstName}
          onChange={handleOnChange}
        />
        <InputField
          label={t("form.lastName")}
          placeholder={t("form.lastNamePlaceholder")}
          type={"text"}
          name="lastName"
          value={data.lastName}
          onChange={handleOnChange}
        />
        <InputField
          label={t("form.phoneNumber")}
          placeholder={t("form.phoneNumberPlaceholder")}
          type={"number"}
          className={"col-span-2"}
          name="phone"
          value={data.phone}
          onChange={handleOnChange}
        />
        <InputField
          label={t("form.email")}
          placeholder={t("form.emailPlaceholder")}
          className={"col-span-2"}
          name="email"
          value={data.email}
          onChange={handleOnChange}
        />
        <InputField
          label={t("form.address")}
          placeholder={t("form.addressPlaceholder")}
          className={"col-span-2"}
          name="address"
          value={data.address}
          onChange={handleOnChange}
        />
        <InputField
          label={t("form.jobTitle")}
          placeholder={t("form.jobTitlePlaceholder")}
          name="jobTitle"
          value={data.jobTitle}
          onChange={handleOnChange}
        />
        <div className={`flex flex-col gap-[6px] w-full`}>
          <label htmlFor="" className="text-[14px] text-[#344054]">
            {t("form.product")}
          </label>
          <select
            style={{ border: "1px solid #D0D5DD" }}
            className=" w-full placeholder:text-[#667085] text-[#667085] p-[10px] px-[14px] outline-none rounded-[8px]"
            value={data.product}
            onChange={handleOnChange}
            name="product"
          >
            <option value="" hidden disabled>
              {t("form.selectProduct")}
            </option>
            {filteredProducts?.map((item, index) => (
              <option
                key={index}
                value={t(item.heading?.en)}
                className="max-w-[160px]"
              >
                {t(item.heading?.en)}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] w-full col-span-2">
          <label htmlFor="" className="text-[14px] text-[#344054]">
            {t("form.message")}
          </label>
          <textarea
            style={{ border: "1px solid #D0D5DD" }}
            cols="30"
            rows="10"
            className="w-full placeholder:text-[#667085] text-[#667085] p-[10px] px-[14px] outline-none rounded-[8px] resize-none"
            value={data.message}
            onChange={handleOnChange}
            name="message"
          ></textarea>
        </div>
        <button
          onClick={handleSubmit}
          className="flex items-center gap-[24px] px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px] justify-center w-full col-span-2"
        >
          {!isLoading && t("form.getStarted")}
          {isLoading && (
            <img
              src="https://i.gifer.com/ZKZg.gif"
              className="w-[24px]"
              alt=""
            />
          )}
        </button>
      </div>
      {snackbar && <Snackbar snackbarMessage={message} />}
    </div>
  );
};
export default GetAQoute;
