import security1 from "../images/securityCabins/1.webp";
import security2 from "../images/securityCabins/2.png";
import security3 from "../images/securityCabins/3.webp";
import security4 from "../images/securityCabins/4.webp";
import security5 from "../images/securityCabins/5.webp";

import boatsElectricPuddles1 from "../images/boatsElectricPuddles/1.jpg";
import boatsElectricPuddles2 from "../images/boatsElectricPuddles/2.jpg";
import boatsElectricPuddles3 from "../images/boatsElectricPuddles/3.jpg";
import boatsElectricPuddles4 from "../images/boatsElectricPuddles/4.jpg";
import boatsElectricPuddles5 from "../images/boatsElectricPuddles/5.jpg";
import boatsElectricPuddles6 from "../images/boatsElectricPuddles/6.jpg";

import portableWashroom1 from "../images/portableWashroom/1.png";
import portableWashroom2 from "../images/portableWashroom/2.png";
import portableWashroom3 from "../images/portableWashroom/3.png";
import portableWashroom4 from "../images/portableWashroom/4.png";

import airplaneModelsUAV2 from "../images/airplaneModelsUAV/2.png";
import airplaneModelsUAV3 from "../images/airplaneModelsUAV/3.jpg";
import airplaneModelsUAV4 from "../images/airplaneModelsUAV/4.jpg";
import airplaneModelsUAV5 from "../images/airplaneModelsUAV/5.jpg";

import kidsBeds1 from "../images/kidsBeds/2.jpg";
import kidsBeds2 from "../images/kidsBeds/3.jpg";
import kidsBeds3 from "../images/kidsBeds/4.jpg";
import kidsBeds4 from "../images/kidsBeds/5.jpeg";
import kidsBeds5 from "../images/kidsBeds/6.jpg";

import playLandItems1 from "../images/playLandItems/1.jpg";
import playLandItems2 from "../images/playLandItems/2.png";
import playLandItems3 from "../images/playLandItems/3.jpg";
import playLandItems4 from "../images/playLandItems/4.jpg";
import playLandItems5 from "../images/playLandItems/5.jpg";
import playLandItems6 from "../images/playLandItems/6.jpg";

import monuments1 from "../images/monuments/1.png";
import monuments2 from "../images/monuments/2.jpg";
import monuments3 from "../images/monuments/3.jpg";

import fiberglassDecoration1 from "../images/fiberglassDecoration/1.webp";
import fiberglassDecoration2 from "../images/fiberglassDecoration/2.jpg";
import fiberglassDecoration3 from "../images/fiberglassDecoration/3.png";
import fiberglassDecoration4 from "../images/fiberglassDecoration/4.png";
import fiberglassDecoration5 from "../images/fiberglassDecoration/5.png";
import fiberglassDecoration6 from "../images/fiberglassDecoration/6.png";

import threedHouseMockups1 from "../images/3dHouseMockups/1.jpg";

import fiberGlassOutdoor1 from "../images/fiberGlassOutdoor/1.jpg";
import fiberGlassOutdoor2 from "../images/fiberGlassOutdoor/2.jpg";
import fiberGlassOutdoor3 from "../images/fiberGlassOutdoor/3.jpg";
import fiberGlassOutdoor4 from "../images/fiberGlassOutdoor/4.jpg";
import fiberGlassOutdoor5 from "../images/fiberGlassOutdoor/5.jpg";
import fiberGlassOutdoor6 from "../images/fiberGlassOutdoor/6.jpg";

import fiberGlassAnimalStaute1 from "../images/fiberGlassAnimalStaute/1.jpg";
import fiberGlassAnimalStaute2 from "../images/fiberGlassAnimalStaute/2.png";
import fiberGlassAnimalStaute3 from "../images/fiberGlassAnimalStaute/3.jpg";
import fiberGlassAnimalStaute4 from "../images/fiberGlassAnimalStaute/4.jpg";
import fiberGlassAnimalStaute5 from "../images/fiberGlassAnimalStaute/5.png";

import fiberGlassComercial1 from "../images/fiberGlassComercial/1.png";
import fiberGlassComercial2 from "../images/fiberGlassComercial/2.png";
import fiberGlassComercial3 from "../images/fiberGlassComercial/3.png";
import fiberGlassComercial4 from "../images/fiberGlassComercial/4.png";

import fiberGlassKitchenCabinent1 from "../images/fiberGlassKitchenCabinent/1.jpg";
import fiberGlassKitchenCabinent2 from "../images/fiberGlassKitchenCabinent/2.jpg";
import fiberGlassKitchenCabinent3 from "../images/fiberGlassKitchenCabinent/3.jpg";
import fiberGlassKitchenCabinent4 from "../images/fiberGlassKitchenCabinent/4.jpg";

import fiberGlassResidential1 from "../images/fiberGlassResidential/1.png";
import fiberGlassResidential2 from "../images/fiberGlassResidential/2.png";
import fiberGlassResidential3 from "../images/fiberGlassResidential/3.png";
import fiberGlassResidential4 from "../images/fiberGlassResidential/4.png";

import carParkingSheds1 from "../images/carParkingSheds/1.png";
import carParkingSheds2 from "../images/carParkingSheds/2.png";
import carParkingSheds3 from "../images/carParkingSheds/3.png";
import carParkingSheds4 from "../images/carParkingSheds/4.png";

import enternceGates1 from "../images/enternceGates/1.jpg";

import canopy1 from "../images/canopy/1.png";
import canopy2 from "../images/canopy/2.png";
import canopy3 from "../images/canopy/3.png";
import canopy4 from "../images/canopy/4.png";

import schoolFurniture1 from "../images/schoolFurniture/1.png";
import schoolFurniture2 from "../images/schoolFurniture/2.png";
import schoolFurniture3 from "../images/schoolFurniture/3.png";
import schoolFurniture4 from "../images/schoolFurniture/4.png";

import planters1 from "../images/planters/2.png";
import planters2 from "../images/planters/3.png";
import planters3 from "../images/planters/6.png";
import planters4 from "../images/planters/1.png";
import planters5 from "../images/planters/4.png";
import planters6 from "../images/planters/5.png";

import { useTranslation } from 'react-i18next';

export const productData = [
    {
        id: "1",
        name: "ProductsDetail.securityCabins.name",
        imgUrl: "images/securityCabins/1.webp",
        images: [
            {
                id: "1",
                imgUrl: security1,
            },
            {
                id: "2",
                imgUrl: security2,
            },
            {
                id: "3",
                imgUrl: security3,
            },
            {
                id: "4",
                imgUrl: security4,
            },
            {
                id: "5",
                imgUrl: security5,
            },
        ],
        detail: {
            heading: "ProductsDetail.securityCabins.heading",
            detail: `ProductsDetail.securityCabins.detail`
        }
    },
    {
        id: "2",
        name: "ProductsDetail.boatsElectricPuddles.name",
        imgUrl: "images/boatsElectricPuddles/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: boatsElectricPuddles1,
            },
            {
                id: '2',
                imgUrl: boatsElectricPuddles2,
            },
            {
                id: '3',
                imgUrl: boatsElectricPuddles3,
            },
            {
                id: '4',
                imgUrl: boatsElectricPuddles4,
            },
            {
                id: '5',
                imgUrl: boatsElectricPuddles5,
            },
            {
                id: '6',
                imgUrl: boatsElectricPuddles6,
            },
        ],
        detail: {
            heading: "ProductsDetail.boatsElectricPuddles.heading",
            detail: "ProductsDetail.boatsElectricPuddles.detail"
        }
    },
    {
        id: "3",
        name: "ProductsDetail.portableWashroom.name",
        imgUrl: "images/portableWashroom/1.png",
        images: [
            {
                id: '1',
                imgUrl: portableWashroom1,
            },
            {
                id: '2',
                imgUrl: portableWashroom2,
            },
            {
                id: '3',
                imgUrl: portableWashroom3,
            },
            {
                id: '4',
                imgUrl: portableWashroom4,
            },
        ],
        detail: {
            heading: "ProductsDetail.portableWashroom.heading",
            detail: "ProductsDetail.portableWashroom.detail"
        }
    },
    {
        id: "4",
        name: "ProductsDetail.airplaneModelsUAV.name",
        imgUrl: "images/airplaneModelsUAV/2.png",
        images: [
            {
                id: '2',
                imgUrl: airplaneModelsUAV2,
            },
            {
                id: '3',
                imgUrl: airplaneModelsUAV3,
            },
            {
                id: '4',
                imgUrl: airplaneModelsUAV4,
            },
            {
                id: '5',
                imgUrl: airplaneModelsUAV5,
            },
        ],
        detail: {
            heading: "ProductsDetail.airplaneModelsUAV.heading",
            detail: "ProductsDetail.airplaneModelsUAV.detail"
        }
    },
    {
        id: "5",
        name: "ProductsDetail.kidsBeds.name",
        imgUrl: "images/kidsBeds/3.jpg",
        images: [
            {
                id: '2',
                imgUrl: kidsBeds1,
            },
            {
                id: '3',
                imgUrl: kidsBeds2,
            },
            {
                id: '4',
                imgUrl: kidsBeds3,
            },
            {
                id: '5',
                imgUrl: kidsBeds4,
            },
            {
                id: '6',
                imgUrl: kidsBeds5,
            },
        ],
        detail: {
            heading: "ProductsDetail.kidsBeds.heading",
            detail: "ProductsDetail.kidsBeds.detail"
        }
    },
    {
        id: "6",
        name: "ProductsDetail.playLandItems.name",
        imgUrl: "images/playLandItems/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: playLandItems1,
            },
            {
                id: '2',
                imgUrl: playLandItems2,
            },
            {
                id: '3',
                imgUrl: playLandItems3,
            },
            {
                id: '4',
                imgUrl: playLandItems4,
            },
            {
                id: '5',
                imgUrl: playLandItems5,
            },
            {
                id: '6',
                imgUrl: playLandItems6,
            },
        ],
        detail: {
            heading: "ProductsDetail.playLandItems.heading",
            detail: "ProductsDetail.playLandItems.detail"
        }
    },
    {
        id: "7",
        name: "ProductsDetail.monuments.name",
        imgUrl: "images/monuments/1.png",
        images: [
            {
                id: '1',
                imgUrl: monuments1,
            },
            {
                id: '2',
                imgUrl: monuments2,
            },
            {
                id: '3',
                imgUrl: monuments3,
            },
        ],
        detail: {
            heading: "ProductsDetail.monuments.heading",
            detail: "ProductsDetail.monuments.detail"
        }
    },
    {
        id: "8",
        name: "ProductsDetail.fiberglassDecoration.name",
        imgUrl: "images/fiberglassDecoration/1.webp",
        images: [
            {
                id: '1',
                imgUrl: fiberglassDecoration1,
            },
            {
                id: '2',
                imgUrl: fiberglassDecoration2,
            },
            {
                id: '3',
                imgUrl: fiberglassDecoration3,
            },
            {
                id: '4',
                imgUrl: fiberglassDecoration4,
            },
            {
                id: '5',
                imgUrl: fiberglassDecoration5,
            },
            {
                id: '6',
                imgUrl: fiberglassDecoration6,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberglassDecoration.heading",
            detail: "ProductsDetail.fiberglassDecoration.detail"
        }
    },
    {
        id: "9",
        name: "ProductsDetail.threedHouseMockups.name",
        imgUrl: "images/3dHouseMockups/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: threedHouseMockups1,
            },
        ],
        detail: {
            heading: "ProductsDetail.threedHouseMockups.heading",
            detail: "ProductsDetail.threedHouseMockups.detail"
        }
    },
    {
        id: "10",
        name: "ProductsDetail.fiberGlassOutdoor.name",
        imgUrl: "images/fiberGlassOutdoor/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: fiberGlassOutdoor1,
            },
            {
                id: '2',
                imgUrl: fiberGlassOutdoor2,
            },
            {
                id: '3',
                imgUrl: fiberGlassOutdoor3,
            },
            {
                id: '4',
                imgUrl: fiberGlassOutdoor4,
            },
            {
                id: '5',
                imgUrl: fiberGlassOutdoor5,
            },
            {
                id: '6',
                imgUrl: fiberGlassOutdoor6,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberGlassOutdoor.heading",
            detail: "ProductsDetail.fiberGlassOutdoor.detail"
        }
    },
    {
        id: "11",
        name: "ProductsDetail.fiberGlassAnimalStaute.name",
        imgUrl: "images/fiberGlassAnimalStaute/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: fiberGlassAnimalStaute1,
            },
            {
                id: '2',
                imgUrl: fiberGlassAnimalStaute2,
            },
            {
                id: '3',
                imgUrl: fiberGlassAnimalStaute3,
            },
            {
                id: '4',
                imgUrl: fiberGlassAnimalStaute4,
            },
            {
                id: '5',
                imgUrl: fiberGlassAnimalStaute5,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberGlassAnimalStaute.heading",
            detail: "ProductsDetail.fiberGlassAnimalStaute.detail"
        }
    },
    {
        id: "12",
        name: "ProductsDetail.fiberGlassComercial.name",
        imgUrl: "images/fiberGlassComercial/1.png",
        images: [
            {
                id: '1',
                imgUrl: fiberGlassComercial1,
            },
            {
                id: '2',
                imgUrl: fiberGlassComercial2,
            },
            {
                id: '3',
                imgUrl: fiberGlassComercial3,
            },
            {
                id: '4',
                imgUrl: fiberGlassComercial4,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberGlassComercial.heading",
            detail: "ProductsDetail.fiberGlassComercial.detail"
        }
    },
    {
        id: "13",
        name: "ProductsDetail.fiberGlassKitchenCabinent.name",
        imgUrl: "images/fiberGlassKitchenCabinent/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: fiberGlassKitchenCabinent1,
            },
            {
                id: '2',
                imgUrl: fiberGlassKitchenCabinent2,
            },
            {
                id: '3',
                imgUrl: fiberGlassKitchenCabinent3,
            },
            {
                id: '4',
                imgUrl: fiberGlassKitchenCabinent4,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberGlassKitchenCabinent.heading",
            detail: "ProductsDetail.fiberGlassKitchenCabinent.detail"
        }
    },
    {
        id: "14",
        name: "ProductsDetail.fiberGlassResidential.name",
        imgUrl: "images/fiberGlassResidential/1.png",
        images: [
            {
                id: '1',
                imgUrl: fiberGlassResidential1,
            },
            {
                id: '2',
                imgUrl: fiberGlassResidential2,
            },
            {
                id: '3',
                imgUrl: fiberGlassResidential3,
            },
            {
                id: '3',
                imgUrl: fiberGlassResidential4,
            },
        ],
        detail: {
            heading: "ProductsDetail.fiberGlassResidential.heading",
            detail: "ProductsDetail.fiberGlassResidential.detail"
        }
    },
    {
        id: "15",
        name: "ProductsDetail.carParkingSheds.name",
        imgUrl: "images/carParkingSheds/1.png",
        images: [
            {
                id: '1',
                imgUrl: carParkingSheds1,
            },
            {
                id: '2',
                imgUrl: carParkingSheds2,
            },
            {
                id: '3',
                imgUrl: carParkingSheds3,
            },
            {
                id: '4',
                imgUrl: carParkingSheds4,
            },
        ],
        detail: {
            heading: "ProductsDetail.carParkingSheds.heading",
            detail: "ProductsDetail.carParkingSheds.detail"
        }
    },
    {
        id: "16",
        name: "ProductsDetail.enternceGates.name",
        imgUrl: "images/enternceGates/1.jpg",
        images: [
            {
                id: '1',
                imgUrl: enternceGates1,
            },
        ],
        detail: {
            heading: "ProductsDetail.enternceGates.heading",
            detail: "ProductsDetail.enternceGates.detail"
        }
    },
    {
        id: "17",
        name: "ProductsDetail.canopy.name",
        imgUrl: "images/canopy/1.png",
        images: [
            {
                id: '1',
                imgUrl: canopy1,
            },
            {
                id: '2',
                imgUrl: canopy2,
            },
            {
                id: '3',
                imgUrl: canopy3,
            },
            {
                id: '4',
                imgUrl: canopy4,
            }
        ],
        detail: {
            heading: "ProductsDetail.canopy.heading",
            detail: "ProductsDetail.canopy.detail"
        }
    },
    {
        id: "18",
        name: "ProductsDetail.schoolFurniture.name",
        imgUrl: "images/schoolFurniture/1.png",
        images: [
            {
                id: '1',
                imgUrl: schoolFurniture1,
            },
            {
                id: '2',
                imgUrl: schoolFurniture2,
            },
            {
                id: '3',
                imgUrl: schoolFurniture3,
            },
            {
                id: '4',
                imgUrl: schoolFurniture4,
            },
        ],
        detail: {
            heading: "ProductsDetail.schoolFurniture.heading",
            detail: "ProductsDetail.schoolFurniture.detail"
        }
    },
    {
        id: "19",
        name: "ProductsDetail.planters.name",
        imgUrl: "images/planters/3.png",
        images: [
            {
                id: '2',
                imgUrl: planters1,
            },
            {
                id: '3',
                imgUrl: planters2,
            },
            {
                id: '3',
                imgUrl: planters3,
            },
        ],
        detail: {
            heading: "ProductsDetail.planters.heading",
            detail: "ProductsDetail.planters.detail"
        }
    },
    {
        id: "20",
        name: "ProductsDetail.waterAndChemical.name",
        imgUrl: "images/planters/1.png",
        images: [
            {
                id: '1',
                imgUrl: planters4,
            },
            {
                id: '2',
                imgUrl: planters5,
            },
            {
                id: '3',
                imgUrl: planters6,
            },
        ],
        detail: {
            heading: "ProductsDetail.waterAndChemical.heading",
            detail: "ProductsDetail.waterAndChemical.detail"
        }
    },
];
