import React from "react";
import { selectIsLoading, setIsLoading } from "../redux/LoadingReducer";
import { useDispatch, useSelector } from "react-redux";
function useHandleLoading() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const fnHandleLoading = (isLoading) => {
    dispatch(setIsLoading(isLoading));
  };
  return { isLoading, fnHandleLoading };
}
export default useHandleLoading;