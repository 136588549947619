import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StillSection from "../../components/stillSection/StillSection";

import { productData } from "../../productData";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../components/image/Image";
import ServiceBanner from "./components/ServiceBanner";
import Tagline from "./components/Tagline";
import useFirebaseData from "../../hooks/useFirebaseData";
import DzText from "../../components/common/dzText/DzText";

const Services = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [filteredProducts, setFilteredProducts] = useState(productData);
  const handleNavigate = (id) => {
    navigate(`/serviceDetail/${id}`);
  };
  const handleNavigateQuate = (id) => {
    navigate(`/getAQoute`, { state: { id: id } });
  };
  const { data, fetchData } = useFirebaseData();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentServices");
    };
    fetchHeroData();
  }, []);
  return (
    <>
      <ServiceBanner />
      <Tagline />
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(336px,_1fr))] gap-[24px] px-[4vw] py-[64px]">
        {data?.map((item, index) => (
          <div
            onClick={() => handleNavigate(item?.id)}
            className="cursor-pointer flex flex-col shadow-md w-full rounded-[6px] relative overflow-hidden"
          >
            <Image
              src={item?.images?.[0]?.url}
              className="max-w-full inset-1 shadow-md max-h-[160px] object-cover rounded-[0px]"
              alt=""
            />
            <div className="flex flex-col p-[12px] gap-[6px]">
              <DzText className="text-[#000000] truncate w-full text-[20px] font-[500] capitalize">
                {item?.heading}
              </DzText>
              <div className="mt-[12px] flex items-center gap-[8px] w-full">
                <div
                  onClick={(e) => {
                    handleNavigateQuate(item?.id);
                    e.stopPropagation();
                  }}
                  className="cursor-pointer flex items-center font-sans justify-center gap-[24px] px-[4px] w-full py-[8px] bg-[#EAEAEB] text-[14px] font-[600] text-[#1D1D1B] rounded-[8px]"
                >
                  Get a Quote
                </div>
                <div className="flex items-center font-sans justify-center gap-[24px] px-[4px] w-full py-[8px] bg-[#FF5C35] text-[14px] font-[600] text-[#fff] rounded-[8px]">
                  Check Details
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <StillSection />
    </>
  );
};

export default Services;
