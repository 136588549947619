import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFirebaseData from "../../../hooks/useFirebaseData";
import DzText from "../../../components/common/dzText/DzText";

const ContactCards = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFirebaseData();
  useEffect(() => {
    const fetchHeroData = async () => {
      await fetchData("contentContectCards");
    };
    fetchHeroData();
  }, []);
  console.log(data, "data")
  return (
    <div className="grid grid-cols-4 gap-[24px] px-[4vw] py-[64px] max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
      {data?.map((card) => {
        return (
          <div className="bg-[#ecf1f5] flex flex-col gap-[64px] p-[24px] rounded-[10px] items-start">
            <img src={card?.icon} className="w-[48px]" alt="" />
            <div className="flex flex-col gap-[20px]">
              <div className="flex flex-col gap-[8px]">
                <DzText className="text-[#101828] text-[20px] font-[600]">
                  {card?.heading}
                </DzText>
                <DzText className="text-[#475467] text-[16px] font-[400]">
                  {card?.detail}
                </DzText>
              </div>
              <DzText
                className="text-[#FF5C35] font-[600]"
              >
                {card?.info}
              </DzText>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ContactCards;
