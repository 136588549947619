// StillSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StillSection = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col w-full items-center gap-[32px] px-[4vw] py-[64px]">
            <img src="images/avatarGroup.png" className='max-w-[120px]' alt="" />
            <div className="flex flex-col gap-[12px] text-center">
                <h4 className='text-[#1e232e] font-[700] text-[20px]'>
                    {t('StillSection.heading')}
                </h4>
                <p className='text-[#475467] text-[16px]'>
                    {t('StillSection.description')}
                </p>
            </div>
            <Link to={'/getAQoute'} className='flex items-center gap-[24px] font-[500] w-fit px-[24px] py-[12px] bg-[#FF5C35] text-[#fff] rounded-[8px]'>
                {t('StillSection.button_text')}
            </Link>
        </div>
    );
};

export default StillSection;